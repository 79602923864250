import styled from '@emotion/styled'
import { Button, css } from '@mui/material'
import { MouseEventHandler } from 'react'
import { colors, screenSize } from '../tokens'

interface ComponentProps {
  other?: {}
  // @ts-ignore: JSX type error
  children: JSX.Element | JSX.Element[] | string
  fullWidth?: boolean
  variant?: 'contained' | 'text' | 'outlined' | undefined
  mode?: 'light' | 'dark' | undefined
  onClick?: MouseEventHandler
  disabled?: boolean
  href?: string
  style?: any
}

interface OtherButtonStylesType {
  color: string
  backgroundColor?: string
  opacity: string
}

export default function ButtonSecondary({
  children,
  variant = 'outlined',
  mode = 'dark',
  fullWidth,
  onClick,
  disabled,
  href,
  style,
  ...other
}: ComponentProps) {
  const otherButtonStyles: OtherButtonStylesType = {
    color: mode === 'dark' ? 'white' : colors.globalDarkBlue,
    opacity: disabled ? '0.75' : '1',
    ...style,
  }
  if (disabled) {
    otherButtonStyles.backgroundColor = mode === 'light' ? '#ddd' : '#888'
    otherButtonStyles.color = '#fff !important'
  }
  return (
    <StyledButton
      fullWidth={fullWidth}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      style={otherButtonStyles}
      href={href}
      {...other}
    >
      {children}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  background-color: transparent;
  height: 45px;
  width: 100%;
  font-size: 16px;
  border-color: ${colors.globalMediumBlue2};

  ${screenSize.large(css`
    max-width: 240px;
    font-size: 13px;
    /* sXcXFe43ew */
  `)}
`
