import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { borderRadii, colors, constants, screenSize } from '../tokens'

const Primary = styled.section`
  margin-top: ${constants.globalBufferSmall};
  margin-bottom: ${constants.globalBufferSmall};
  padding: ${constants.globalBufferSmall};
  font-family: sans-serif;

  ${screenSize.large(css`
    min-width: ${constants.desktopContentWidth};
    margin: ${constants.globalBufferMedium} auto;
  `)}
`

const Secondary = styled(Primary)`
  background: ${colors.transparentWhite};
  // -webkit-backdrop-filter: blur(10px);
  // backdrop-filter: blur(10px);
  color: #001;
  border-radius: ${borderRadii.sm};
  ${screenSize.large(css`
    padding: ${constants.globalBufferLarge};
  `)}
`

const Tertiary = styled(Primary)`
  border-radius: ${borderRadii.sm};

  background: ${colors.transparentWhiteLight};
  -webkit-backdrop-filter: blur(${constants.globalBufferXS});
  backdrop-filter: blur(${constants.globalBufferXS});
  color: ${colors.globalLighterBlue};
`

interface ComponentProps {
  variant?: '' | 'primary' | 'secondary' | 'tertiary'
  // @ts-ignore: JSX type error
  children?: JSX.Element | JSX.Element[] | string | undefined
  fullscreen?: boolean
  style?: {}
  otherStyles?: {}
}

export default function Section({
  variant = 'primary',
  children,
  fullscreen,
  style,
  otherStyles,
  ...other
}: ComponentProps) {
  let fullscreenStyles = {}
  if (fullscreen) {
    fullscreenStyles = {
      margin: 0,
      padding: '0px !important',
      minWidth: '100%',
    }
  }
  return variant === 'secondary' ? (
    <Secondary style={{ ...style, ...otherStyles, ...fullscreenStyles }} {...other}>
      {children}
    </Secondary>
  ) : variant === 'tertiary' ? (
    <Tertiary style={{ ...style, ...otherStyles, ...fullscreenStyles }} {...other}>
      {children}
    </Tertiary>
  ) : (
    <Primary style={{ ...style, ...otherStyles, ...fullscreenStyles }} {...other}>
      {children}
    </Primary>
  )
}
